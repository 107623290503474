<script>
import { required, email } from "vuelidate/lib/validators";
// import firebase from "firebase";

import {
  authMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      tryingToLogIn: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...notificationMethods,

    sociallogin() {
      this.submitted = true;
      if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.logInSocial()
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                console.log("retornou login")
                
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                
                if(this.$route.query.redirectFrom.includes( "/logout")){
                  this.$route.query.redirectFrom = null
                }
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              })
              .catch(error => {
                // console.error("retornou login" + error.message + " " + error);
                
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
          );
      }
    },

    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase"  ) {
          
          this.tryingToLogIn = true;
          
          this.authError = null;
          return (
            this.logIn({
              email: this.email,
              password: this.password
            }).catch(error => {
              
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                if(token){
                  this.$router.push(
                    this.$route.query.redirectFrom || { name: "home" }
                  );
                }
              })
              
          );
        } else {
          const { email, password } = this;
          if (email && password) {
            this.login({ email, password });
          }
        }
      }
    }
  }
};
</script>

<template>
  
  <div>
   
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0 ">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100 ">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Bem vindo de volta!</h4>
                        <p class="text-muted">Faça seu login no Pronto Clínica Sphera.</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                      >{{notification.message}}</b-alert>

                      <div class="card border border-primary">
                        <div class="card-body">
                          <div class=" text-center">
                                  <button @click="sociallogin" class="btn btn-primary">
                                  <i class="ri-google-fill align-middle mr-2"></i> Login com Google 
                                  </button>
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <h4>OU</h4>
                      </div>

                      <div class="p-2 mt-5 card border border-primary">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email é requerido.</span>
                              <span v-if="!$v.email.email">Por favor, entre com um email válido.</span>
                            </div>
                           
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Senha</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >A senha é requerida.</div>
                          </div>
                          
                          
                          <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="authError"
                        show
                        dismissible
                      >{{authError}}</b-alert>
                          
                          <div v-show="tryingToLogIn" class="loading-spinner">
                            <b-spinner v-show="tryingToLogIn" small></b-spinner> Carregando...
                          </div>
                          <!-- <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Lembrar de mim.</label>
                          </div> -->

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              :disabled="tryingToLogIn"
                            >Login</button>
                          </div>
                          
                            
                                

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Esqueci minha senha
                            </router-link>
                          </div>
                        </form>
                      </div> 
                     
                      <div class="mt-5 text-center">
                        <!-- <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p> -->
                        <p>
                          © 2021 Clínica Sphera. Desenvolvido
                          <i class="mdi mdi-desk-lamp text-info"></i> por Allogic Tecnologia
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>